<template>
  <div class="column pt-0">
    <h2 class="has-text-weight-semibold is-size-5 mb-4">Список компаний</h2>
    <BaseTable
      :data="companies"
      :columns="columns"
      :loading="isLoading"
      :total="total"
      :per-page="perPage"
      :current-page="currentPage"
      @change="fetchCompanies"
      @rowClick="onRowClick"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/Base/Table";

export default {
  components: { BaseTable },
  data() {
    return {
      total: 0,
      companies: [],
      isLoading: true,
      currentPage: 1,
      perPage: 10,
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "name",
          label: "Компания",
          centered: true
        },
        {
          field: "bin",
          label: "Бин",
        },
        {
          field: "created",
          label: "Зарегистрирован",
          centered: true,
        },
      ],
    };
  },
  mounted() {
    this.fetchCompanies();
  },
  methods: {
    async fetchCompanies(page) {
      this.currentPage = page || 1
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`admin/companies/paginate/${(this.currentPage - 1) * 10}/${this.perPage}`)
        this.companies = data?.data || [];
        this.total = data?.count;
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false;
    },
    onRowClick(item) {
      this.$router.push(`/company-editor/${item.id}`);
    },
  },
};
</script>
